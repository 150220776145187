<template>
  <div class="login fill-height">
    <v-layout align-center justify-center class="fill-height">
      <v-flex xs12 sm8 md4>
        <v-alert
          v-if="message"
          dense
          text
          :type="message === 'login_fail' ? 'error' : 'success'"
          elevation="5"
          class="float-start"
          dismissible
          transition="fade-transition"
        >
          {{ message }}
        </v-alert>
        <v-card max-width="420" class="elevation-12 mx-auto">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Login</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="username"
                :error-messages="nameErrors"
                required
                @input="$v.username.$touch()"
                @blur="$v.username.$touch()"
                prepend-icon="mdi-account"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :error-messages="passwordErrors"
                required
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-on:keyup.native.enter="login"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="login">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>


<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapMutations, mapState } from "vuex";

export default {
  mixins: [validationMixin],

  validations: {
    username: { required },
    password: { required },
  },

  data: () => ({
    username: "",
    password: "",
    showPassword: false,
  }),

  computed: {
    ...mapState(["user_info", "message"]),
    nameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push("username is required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("password is required.");
      return errors;
    },
  },

  methods: {
    ...mapMutations(["SET_MESSAGE"]),

    async login() {
      this.$v.$touch();

      this.$debug("invalid", this.$v.$invalid);
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        return;
      }

      this.$store.dispatch("login", {
        username: this.username,
        password: this.password,
      });
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    },
  },
};
</script>

<style scoped>
.login {
  background: transparent;
}
.v-alert {
  position: fixed;
  left: 50%;
  top: 120px;
  transform: translate(-50%, -50%);
  width: 500px;
  margin: 0 auto;
}
</style>